.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.box-about {
  margin: 1rem;
}

.about-img {
  top: 10px;
  width: 21rem;
}
.study-img {
  width: 14.5rem;
  height: 14rem;
}

.study-img-stepit {
  width: 15rem;
  height: 14rem;
  border-radius: 35px;
}
